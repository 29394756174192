import _ from 'lodash'

export const initialState = {
    teamsLoading: false,
    teams: [],
    team: {
        id: null,
        event: null,
        title: null,
        point: null,
        created_at: null,
    },
    metaTeam: {count: 0},
}

export default function TeamReducer(state = initialState, action) {
    switch (action.type) {
        case "FETCH_TEAM_PROCESS":
            return {...state, teamsLoading: true}
        case "FETCH_TEAM_SUCCESS":
            let teamsResponse = {...state, teamsLoading: false}
            return _.isArray(action.payload)
                ? {...teamsResponse, teams: action.payload, metaTeam: action.meta}
                : {...teamsResponse, team: action.payload}
        case "FETCH_TEAM_FAILED":
            return {...state, teamsLoading: false, teams: initialState.teams}

        default:
            return state
    }
}
